<template>
  <div class="d-flex">
    
  </div>
</template>

<script>
export default {
  data () {
    return {

    }
  },
  computed: {
    
  },
  methods: {
    
  }
}
</script>
