<template>
  <div class="d-flex align-center h-100">
      <v-menu offset-y rounded="0">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="blue-grey"
            dark
            v-bind="attrs"
            v-on="on"
            min-width="40"
            height="42"
            class="px-0 mt-1"
          >
            <v-icon>mdi-account-star</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item>
            <v-list-item-title class="text-button">
             {{ $store.state.auth.user.name }}
            </v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item @click="profile">
            <v-list-item-title>
              <v-icon left>mdi-square-edit-outline</v-icon>
              Профиль
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click="logout" >
            <v-list-item-title>
              <v-icon left>mdi-exit-to-app</v-icon>
              Выход
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
     <specialists-form :open="openExpertForm" :editId="openId" @close="closeForm"/>
     <users-form :open="openUserForm" :editId="openId" @close="closeForm"/>
  </div>
</template>

<script>
import SpecialistsForm from '../users/SpecialistsForm'
import UsersForm from '../users/UsersForm'
export default {
  components: {
    SpecialistsForm,
    UsersForm,
  },
  data () {
    return {
      openExpertForm: false,
      openUserForm: false,
      openId: null,
    }
  },
  computed: {
    isExpert() {
      return this.$store.getters.isAdmin;
    }
  },
  methods: {
    profile() {
      this.openId = this.$store.state.auth.user.id;
      if (this.isExpert) {
        this.openExpertForm = true;
      } else {
        this.openUserForm = true;
      }
    },
    logout() {
      this.$store.dispatch('logout');
      this.$router.push('/login');
    },
    closeForm() {
      this.openId = null;
      this.openExpertForm = false;
    },
  }
}
</script>
