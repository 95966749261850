import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'

Vue.use(VueRouter)


const routes = [{
        path: '/',
        redirect: '/analytics',
        meta: {
            requiresAuth: true
        },
    },
    {
        path: '/analytics',
        name: 'Analytics',
        meta: {
            requiresAuth: true
        },
        component: () =>
            import ('../views/Analytics.vue')
    },
    {
        path: '/data',
        name: 'Data',
        meta: {
            requiresAuth: true,
            requiresExpert: true,
        },
        component: () =>
            import ('../views/Data.vue')
    },
    {
        path: '/specialists',
        name: 'Specialist',
        meta: {
            requiresAuth: true,
            requiresAdmin: true,
        },
        component: () =>
            import ('../views/Specialists.vue')
    },
    {
        path: '/users',
        name: 'Users',
        meta: {
            requiresAuth: true
        },
        component: () =>
            import ('../views/users/UsersIndex.vue')
    },
    {
        path: '/users/:id',
        name: 'User',
        meta: {
            requiresAuth: true
        },
        component: () =>
            import ('../views/users/UserShow.vue')
    },
    {
        path: '/syllabi',
        name: 'Syllabi',
        meta: {
            requiresAuth: true
        },
        component: () =>
            import ('../views/syllabi/SyllabiIndex')
    },
    {
        path: '/syllabi/create',
        name: 'Syllabi Create',
        meta: {
            requiresAuth: true
        },
        component: () =>
            import ('../views/syllabi/CreateSyllabus')
    },
    {
        path: '/syllabi/:id/edit',
        name: 'Syllabi Edit',
        meta: {
            requiresAuth: true
        },
        component: () =>
            import ('../views/syllabi/EditSyllabus')
    },
    {
        path: '/templates/create',
        name: 'TemplateCreate',
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import ('../views/syllabi/CreateTemplate')
    },
    {
        path: '/templates/:id/edit',
        name: 'Templates Edit',
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import ('../views/syllabi/EditTemplate')
    },

    {
        path: '/messages',
        name: 'Messages',
        meta: {
            requiresAuth: true
        },
        component: () =>
            import ('../views/Messages.vue'),
    },
    {
        path: '/trainings',
        name: 'Trainings',
        meta: {
            requiresAuth: true,
            requiresSuperAdmin: true,
        },
        component: () =>
            import ('../views/trainings/TrainingsIndex.vue'),
    },
    {
        path: '/helmets',
        name: 'Helmets',
        meta: {
            requiresAuth: true,
            requiresExpert: true,
        },
        component: () =>
            import ('../views/helmets/HelmetsIndex.vue'),
    },

    {
        path: '/login',
        name: 'Login',
        meta: {
            requiresGuest: true
        },
        component: () =>
            import ('../views/auth/Login.vue')
    },
    {
        path: '/register',
        name: 'Register',
        meta: {
            requiresGuest: true
        },
        component: () =>
            import ('../views/auth/Register.vue')
    },
    {
        path: '/403',
        name: '403',
        meta: {
            requiresAuth: true
        },
        component: () =>
            import ('../views/errors/error403.vue'),
    },
    {
        path: '*',
        name: '404',
        meta: {
            requiresAuth: true
        },
        component: () =>
            import ('../views/errors/error404.vue'),
    },
]

const router = new VueRouter({
        mode: 'history',
        base: process.env.BASE_URL,
        routes
    })
    /* ПЕРЕПИСАТЬ */
router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresSuperAdmin)) {
        if (store.getters.isSuperAdmin) {
            next()
            return
        }
        next('/403')
    } else if (to.matched.some(record => record.meta.requiresAdmin)) {
        if (store.getters.isAdmin) {
            next()
            return
        }
        next('/403')
    } else if (to.matched.some(record => record.meta.requiresExpert)) {
        if (store.getters.isExpert) {
            next()
            return
        }
        next('/403')
    } else if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters.isLoggedIn) {
            next()
            return
        }
        next('/login')
    } else if (to.matched.some(record => record.meta.requiresGuest)) {
        if (!store.getters.isLoggedIn) {
            next()
            return
        }
        next('/analytics')
    }
    next()
})
export default router