<template>
  <v-dialog v-model="open" persistent transition="dialog-top-transition" max-width="800">
    <v-card>
      <v-card-title class='mb-7'>
        <span class="headline">{{ dialogTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row >
            <v-col cols="12" sm="6">
              <v-text-field 
                v-model="name" 
                :error-messages="nameErrors" 
                :rules="[rules.requiredName]" 
                label="Имя" 
                name="name"
                color="teal"/>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field 
                v-model="email" 
                :error-messages="emailErrors" 
                :rules="[rules.requiredEmail, rules.email]" 
                label="Email" 
                name="email"
                color="teal"/>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col cols="12" sm="6">
              <v-text-field 
                v-model="password"
                :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[requiredPass, minPass]"
                :type="showPass ? 'text' : 'password'"
                :error-messages="passErrors"
                @click:append="showPass = !showPass"
                label="Пароль" 
                color="teal" 
                counter
                :hint="passwordHint">
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field 
                v-model="confirmPass"
                type="password"
                
                :rules="[rules.confirmPass]"
                label="Повторить пароль" 
                color="teal">
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6" v-if="isSuperAdmin && $store.state.auth.user.id != editId">
              <v-select
                v-model="role"
                :items="roles"
                item-value="id"
                item-text="name"
                label="Роль"
                color="teal"
                item-color="teal lighten-1"
              ></v-select>
            </v-col>
            <v-col v-if="role == 2" cols="12" :sm="isSuperAdmin ? '6' : '12'">              
              <v-file-input
                truncate-length="15"
                v-model="video"
                label="Видео файл"
              ></v-file-input>
              <a v-if="videoFile" :href="videoFile" target="_blank">Текущее видео</a>
            </v-col>
          </v-row>       
        </v-form>
      </v-card-text>
      <v-card-actions class="pb-4">
        <v-spacer></v-spacer>
        <v-btn dark color="blue-grey lighten-2" @click="close">
          Отмена
        </v-btn>
        <v-btn dark color="teal" @click="save">
          {{ btnText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {

  props: {
    editId: null,
    
    open: {
      default: false,
      type: Boolean
    },
  },
  data () {
    return {
      name: '',  
      email: '',
      password: '',
      confirmPass: '',
      birthday: '',
      sex: 'm',
      tags: null,
      helmet: null,
      mode: 'training',
      group: null,
      expert: null,
      videoFile: null,
      showPass: false,
      menu: false,
      valid: false,
      roles: [
        {id: 2, name: "Специалист"},
        {id: 3, name: "Администратор"},
      ],
      role: 2,
      video: null,
      rules: {
        requiredName: value => !!value || 'Введите имя',
        requiredEmail: value => !!value || 'Введите E-mail адрес',
        confirmPass: value => value === this.password  || 'Пароли не совпадают',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Введите корректный E-mail адрес'
        },
      },
      sexes: [{id:'m',text: 'Муж.'}, {id:'f',text: 'Жен.'}, {id:'o',text: 'Другое'}],
      modes: [{id:'free',text: 'Свободный режим'},{id: 'training', text: 'По программе'}],
    }
  },
  watch: {
    editId() {
      let user = this.experts.find(u => u.id == this.editId);
      if (user) {
        this.name = user.name;
        this.email = user.email;
        this.birthday = user.birthday;
        this.sex = user.sex;
        this.mode = user.mode;
        this.helmet = user.helmet ? user.helmet.id : null;
        this.group = user.group ? user.group.id : null;
        this.expert = user.doctor? user.doctor.id : null;
        this.role = user.role ? user.role.id : null;
        this.videoFile = user.video;
      } else {
        this.clear();
      }
    },
    name() {
      this.errors = this.errors.filter(e => e.source.pointer != "name")
    },
    email() {
      this.errors = this.errors.filter(e => e.source.pointer != "email")
    },
    password() {
      this.errors = this.errors.filter(e => e.source.pointer != "password")
    },
    menu (val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    },
  },  
  computed: {
    birthdayFormat() {
      let date = this.birthday;
      if (!date) {
        date = new Date().toISOString().substr(0, 10)
      }
      return this.$moment(date).format("DD.MM.YYYY")
    },
    btnText() {
      return this.editId ? 'Обновить' : 'Добавить'
    },
    dialogTitle() {
      if (this.$store.state.auth.user.id == this.editId) {
        return 'Обновить профиль'
      }
      return this.editId ? 'Обновить специалиста' : 'Добавить специалиста'
    },
    errors: {
        get() {
          return this.$store.state.auth.authError;
        },
        set(value) {
          this.$store.dispatch('set_errors', value)
        }
    },
    nameErrors: {
        get () {
          return this.errors
            .filter(e => e.source.pointer == "name")
            .map(e => this.$i18n.t(e.detail));
        }
    },
    passErrors: {
        get () {
          return this.errors.filter(e => e.source.pointer == "password");
        }
    },
    emailErrors: {
        get () {
          return this.errors
            .filter(e => e.source.pointer == "email")
            .map(e => this.$i18n.t(e.detail));
        }
    },
    helmets() {
      return this.$store.state.helmets;
    },
    groups() {
      return this.$store.state.userGroups;
    },
    experts() {
      return this.$store.state.experts;
    },
    parentGroups() {
      return this.groups.filter(g => g.parent_id == null)
    },
    sortedGroups() {
      let groups = [];
      this.parentGroups.forEach(parent => {
        groups.push(parent);
        let childs = this.groups.filter(g => g.parent_id == parent.id);
        if (childs.length) {
          groups = groups.concat(childs);
        }
      })
      return groups;
    },
    passwordHint() {
      let hint = '';
      if (this.editId) {
        hint += 'При пустом значении пароль останется прежним. ';
      }
      hint += 'Минимальная длина пароля 6 символов';
      return hint;
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    isSuperAdmin() {
      return this.$store.getters.isSuperAdmin;
    },
  },
  methods: {

    clear() {
      this.name = '';  
      this.email = '';
      this.password = '';
      this.confirmPassword = '';
      this.birthday = '';
      this.sex = 'm';
      this.helmet = null;
      this.mode = 'training';
      this.group = null;
    },
    close() {
      this.clear();
      this.$emit("close");
    },
    save() {
      if (this.$refs.form.validate()) {
        if (this.editId) {
          this.patch()
        } else {
          this.store()
        }
      }
    },
    store() {
      let url = '/users'
      let formData = new FormData();
       if (this.video) {
         formData.append("file", this.video, this.video.name);
       }
       formData.append("name", this.name);
       formData.append("email", this.email);
       formData.append("password", this.password);
       formData.append("role", this.role);
       this.$api.post(url,formData)
        .then(() => {
          this.$store.dispatch('getUserGroups');
          this.$store.dispatch('addMessage', 'Специалист добавлен')
          this.$store.dispatch('getExperts');
          this.close();
        })
        .catch(error => {           
            this.$store.dispatch('addRequestError', error)
        })
    },
    patch() {
      let url = `/users/${this.editId}`
      let formData = new FormData();
       if (this.video) {
         formData.append("file", this.video, this.video.name);
       }
       formData.append("name", this.name);
       formData.append("email", this.email);
       formData.append("password", this.password);
       formData.append("role", this.role);
       this.$api.post(url,formData)
        .then(() => {
          this.$store.dispatch('getUserGroups');
          this.$store.dispatch('addMessage', 'Изменения сохранены')
          this.$store.dispatch('getExperts');
          this.close();
        })
        .catch(error => {           
            this.$store.dispatch('addRequestError', error)
        })
    },
    setBirthday (date) {
      this.$refs.menu.save(date)
    },
    requiredPass(value) {
      if (this.editId) {
        return true;
      }
      return !!value || 'Введите пароль';
    },
    minPass(value) {
      if (this.editId) {
        return true;
      }
      return value.length >= 4 || 'Минимальная длина пароля 4 символов'
    }
  }
}
</script>

<style>

</style>