import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';

import router from './router'
import store from './store'

import VueMoment from 'vue-moment'
import moment from 'moment'

import VueApexCharts from 'vue-apexcharts'
import ChangeExpert from './components/main/ChangeExpert'

import API from './api'

moment.locale('ru');

Vue.config.productionTip = false
Vue.use(VueMoment, { moment });

Vue.prototype.$api = API;

Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

Vue.component('ChangeExpert', ChangeExpert)

new Vue({
    vuetify,
    router,
    store,
    render: h => h(App)
}).$mount('#app')