<template>
  <v-select
      v-model="group"
      :items="sortedGroups"
      item-value="id"
      item-text="name"
      :dense="dense"
      hide-details
      clearable
      class="flex-grow-0"
      label="Группа / Подгруппа"
      color="teal"
      item-color="teal lighten-1"
    >
      <template #[`item`]="{ item }">
        <span class="pl-3" v-if="item.parent_id">{{ item.name }}</span>
        <strong v-else>{{ item.name }}</strong>
      </template>
    </v-select>
</template>

<script>

export default {

  props: {
    value: {
      default: null
    },
    dense: {
      default: true
    }
  },
  data () {
    return {
      group: null
    }
  },
  created() {
    this.group = this.value;
  },
  watch: {
    group() {
      this.$emit('input', this.group)
      this.$emit('change')
    }
  },
  computed: {
    groups() {
      return this.$store.state.userGroups;
    },
    parentGroups() {
      return this.groups.filter(g => g.parent_id == null)
    },
    sortedGroups() {
      let groups = [];
      this.parentGroups.forEach(parent => {
        groups.push(parent);
        let childs = this.groups.filter(g => g.parent_id == parent.id);
        if (childs.length) {
          groups = groups.concat(childs);
        }
      })
      return groups;
    },
  }
}
</script>

<style>

</style>