<template>
  <div>
    <v-snackbar
      timeout="3000"
      color="teal"
      dark
      v-model="snackbar">
      {{ text }}
      <template #action="{ attrs }">
        <v-btn
          color="white"
          text          
          v-bind="attrs"
          @click="closeSnackbar()">
          Закрыть
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  data: () => ({
    snackbar: false,
    text: '',
  }),
  watch: {
    snackbar() {
      if (this.snackbar == false) {
        this.text = '';
        let messages = this.messages;
        messages.shift();
        this.messages = messages;
      }
    },
    messages() {
      if (!this.snackbar && this.messages.length) {
        let message = this.messages[0];
        this.text = message;
        this.snackbar = true;
      }
    },
  },
  computed: {
    messages: {
        get () {
          return this.$store.state.messages
        },
        set (value) {
          this.$store.dispatch('setMessages',value)
        }
    }
  },
  methods: {
    closeSnackbar() {
      this.snackbar = false;
      
    }
  }
}
</script>
