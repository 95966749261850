import Vue from 'vue'
import Vuex from 'vuex'
//import axios from 'axios'
import auth from './auth'
import API from '../api'

Vue.use(Vuex)


export default new Vuex.Store({
    state: {
        showMenu: !!(localStorage.getItem('showMenu') || true),
        mini: +localStorage.getItem('miniMenu'),
        competitors: [],
        locales: ['ru', 'en'],
        locale: 'ru',
        currencies: ['rub', 'dollar', 'euro'],
        currency: 'rub',
        currencyLabel: 'rub.',
        errors: [],
        messages: [],
        trainings: [],
        helmets: [],
        users: [],
        tags: [],
        userGroups: [],
        experts: [],
        expert: null,
        updateItemsList: false
    },
    mutations: {
        toggleMenu(state) {
            state.showMenu = !state.showMenu;
            localStorage.setItem('miniMenu', false)
            localStorage.setItem('showMenu', state.showMenu)
        },
        toggleMini(state) {
            state.mini = !state.mini;
            localStorage.setItem('miniMenu', +state.mini)
        },
        add_error(state, value) {
            state.errors.push(value);
        },
        setErrors(state, value) {
            state.errors = value;
        },
        add_message(state, value) {
            state.messages.push(value);
        },
        setMessages(state, value) {
            state.messages = value;
        },
        setTrainings(state, value) {
            state.trainings = value;
        },
        setHelmets(state, value) {
            state.helmets = value;
        },
        setUserGroups(state, value) {
            state.userGroups = value;
        },
        setUsers(state, value) {
            state.users = value;
        },
        setTags(state, value) {
            state.tags = value;
        },
        setExperts(state, value) {
            state.experts = value;
        },
        setExpert(state, value) {
            state.expert = value;
        },
        updateItemsList(state, value) {
            state.updateItemsList = value;
        },
    },
    actions: {
        set_errors({ commit }, errors) {
            commit('setErrors', errors)
        },
        init(context) {
            if (this.getters.isLoggedIn) {
                API.defaults.headers.common['Authorization'] = 'Bearer ' + this.state.auth.token
                context.dispatch('getTrainings');
                context.dispatch('getUserGroups');
                context.dispatch('getTags');
                if (this.getters.isAdmin) {
                    context.dispatch('getExperts');
                } else {
                    context.dispatch('setExpert', this.state.auth.user.id);
                }
                if (this.getters.isExpert) {
                    context.dispatch('getUsers');
                }

            }
        },

        setExpert(context, value) {
            context.commit('setExpert', value);
            context.dispatch('getHelmets')
        },
        toggleMenu(context) {
            context.commit('toggleMenu');
        },
        toggleMini(context) {
            context.commit('toggleMini');
        },
        setErrors(context, value) {
            context.commit('setErrors', value);
        },
        addRequestError(context, error) {
            let err;
            if (error.response) {
                err = error.response.data;
            } else if (error.request) {
                err = { status: 404, message: "Ошибка сети" }
            } else {
                err = { status: 500, message: "Ошибка сервера" }
            }
            context.commit('add_error', err);
        },
        setMessages(context, value) {
            context.commit('setMessages', value);
        },
        addMessage(context, value) {
            context.commit('add_message', value);
        },
        updateItemsList(context, value) {
            context.commit('updateItemsList', value);
        },
        getTrainings(context) {
            let url = '/trainings';
            API.get(url)
                .then(resp => {
                    context.commit('setTrainings', resp.data._embedded.trainings);
                })
                .catch(error => {
                    context.dispatch('addRequestError', error)
                })
        },
        getHelmets(context) {
            if (!context.getters.isExpert) return
            let url = `/helmets`;
            API.get(url, {
                    params: {
                        user_id: context.state.expert,
                    }
                })
                .then(resp => {
                    context.commit('setHelmets', resp.data._embedded.helmets);
                })
                .catch(error => {
                    context.dispatch('addRequestError', error)
                })
        },
        getUserGroups(context) {
            if (!context.getters.isExpert) return
            let url = '/users-groups';
            API.get(url)
                .then(resp => {
                    context.commit('setUserGroups', resp.data._embedded.groups);
                })
                .catch(error => {
                    context.dispatch('addRequestError', error)
                })
        },
        getUsers(context) {
            let url = '/users';
            API.get(url)
                .then(resp => {
                    let users = resp.data._embedded.users
                        .filter(u => u.role.id == 1)
                        .sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
                    context.commit('setUsers', users);
                })
                .catch(error => {
                    context.dispatch('addRequestError', error)
                })
        },
        getTags(context) {
            let url = '/tags';
            API.get(url)
                .then(resp => {
                    let tags = resp.data._embedded.tags;
                    context.commit('setTags', tags);
                })
                .catch(error => {
                    context.dispatch('addRequestError', error)
                })
        },
        getExperts(context) {
            let url = '/users/experts';
            API.get(url)
                .then(resp => {
                    context.commit('setExperts', resp.data._embedded.users);
                    if (!this.state.expert) {
                        let expert = resp.data._embedded.users.find(e => e.role.id == 2);
                        if (expert) {
                            context.dispatch('setExpert', expert.id);
                        }
                    }
                })
                .catch(error => {
                    context.dispatch('addRequestError', error)
                })
        },
    },
    getters: {

    },
    modules: {
        auth
    }
})