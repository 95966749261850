import API from '../api'

const auth = {
    state: {
        status: '',
        token: localStorage.getItem('token') || '',
        user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {},
        authError: [],
    },
    mutations: {
        auth_request(state) {
            state.status = 'loading'
        },
        auth_success(state, payload) {
            state.status = 'success'
            state.token = payload.token
            state.user = payload.user
        },
        auth_error(state, payload) {
            state.authError = payload
            state.status = 'error'
        },
        clear_auth_errors(state) {
            state.authError = []
            state.status = ''
        },

        logout(state) {
            state.status = '';
            state.token = '';
            state.user = {};
        },
    },
    actions: {
        login({ commit }, user) {
            return new Promise((resolve, reject) => {
                let url = '/login';
                commit('auth_request')
                API.post(url, user)
                    .then(resp => {
                        const token = resp.data._embedded.token;
                        const user = resp.data._embedded.user;
                        localStorage.setItem('token', token)
                        localStorage.setItem('user', JSON.stringify(user))
                        API.defaults.headers.common['Authorization'] = 'Bearer ' + token
                        commit('auth_success', resp.data._embedded)

                        resolve(resp)
                    })
                    .catch(error => {
                        let err;
                        if (error.response) {
                            err = error.response.data;
                            if (error.response.status == 401) {
                                err.pointer = "password";
                            }
                        } else if (error.request) {
                            err = { status: 404, message: "Ошибка сети" }
                        } else {
                            err = { status: 500, message: "Ошибка сервера" }
                        }
                        if (err.pointer) {
                            commit('auth_error', err)
                        } else {
                            commit('add_error', err)
                        }
                        localStorage.removeItem('token')
                        reject(error)
                    })
            })
        },
        register({ commit }, user) {
            return new Promise((resolve, reject) => {
                let url = '/register';
                commit('auth_request')
                API({ url, data: user, method: 'POST' })
                    .then(resp => {
                        const token = resp.data.data.token
                        localStorage.setItem('token', token)
                        API.defaults.headers.common['Authorization'] = token
                        commit('auth_success', resp.data.data)

                        resolve(resp)
                    })
                    .catch(error => {
                        console.log('error 1')
                        let errors = [];
                        if (error.response) {
                            errors = error.response.data.errors;
                        } else if (error.request) {
                            errors = [
                                { status: 404, source: { pointer: "" }, detail: "Server communication error" }
                            ];
                        } else {
                            errors = [
                                { status: 500, source: { pointer: "" }, detail: error.message }
                            ];
                        }
                        commit('auth_error', errors)
                        localStorage.removeItem('token')
                        reject(error)
                    })
            })
        },
        logout({ commit }) {
            return new Promise((resolve) => {
                commit('logout')
                localStorage.removeItem('token')
                delete API.defaults.headers.common['Authorization']
                resolve()
                window.location.reload();
            })
        },
        set_auth_errors({ commit, payload }) {
            commit('auth_error', payload)
        },

    },
    getters: {
        isLoggedIn: state => !!state.token,
        authStatus: state => state.status,
        isSuperAdmin: state => state.user.role == 'superAdmin',
        isAdmin: state => state.user.role == 'superAdmin' || state.user.role == 'admin',
        isExpert: state => state.user.role == 'superAdmin' || state.user.role == 'admin' || state.user.role == 'expert',
    },
}
export default auth