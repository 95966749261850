<template>
  <v-dialog v-model="open" persistent transition="dialog-top-transition" max-width="800">
    <v-card>
      <v-card-title class='mb-7'>
        <span class="headline">{{ dialogTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row >
            
            <v-col cols="12" sm="6">
              <v-text-field 
                v-model="name" 
                :error-messages="nameErrors" 
                :rules="[rules.requiredName]" 
                label="ФИО пользователя" 
                name="name"
                color="teal"/>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field 
                v-model="email" 
                :error-messages="emailErrors" 
                :rules="[rules.requiredEmail, rules.email]" 
                label="Email" 
                name="email"
                color="teal"/>
            </v-col>
          </v-row>
          
          <v-row class="mt-0">
            <v-col cols="12" sm="6">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="birthdayFormat"
                    label="Дата рождения"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    clearable
                    color="teal"
                  ></v-text-field>
                </template>
                <v-date-picker
                  ref="picker"
                  v-model="birthday"
                  :max="new Date().toISOString().substr(0, 10)"
                  min="1920-01-01"
                  color="teal"
                  first-day-of-week="1"
                  :no-title="true"
                  @change="setBirthday"
                  
                >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                v-model="sex"
                :items="sexes"
                item-value="id"
                item-text="text"
                label="Пол"
                color="teal"
                item-color="teal lighten-1"
              ></v-select>
            </v-col>
            
          </v-row>
          <v-row class="mt-0">
            <v-col cols="12" sm="6">
              <v-select
                v-model="mode"
                :items="modes"
                item-value="id"
                item-text="text"
                label="Режим тренировки"
                color="teal"
                item-color="teal lighten-1"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6">
              <select-user-group :dense="false" v-model="group"></select-user-group>
            </v-col>
            <v-col v-if="isAdmin" cols="12" sm="12">
              <v-select
                v-model="expert"
                :items="filtredExperts"
                itemText="name"
                itemValue="id"
                label="Специалист"
                color="teal"
                item-color="teal lighten-1"
              ></v-select>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col cols="12" sm="6">
              <v-text-field 
                
                v-model="password"
                :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[requiredPass, minPass]"
                :type="showPass ? 'text' : 'password'"
                :error-messages="passErrors"
                :name="Math.random()"
                autocomplete="off"
                label="Пароль" 
                color="teal" 
                counter
                @click:append="showPass = !showPass"
                :hint="passwordHint">
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field 
                v-model="confirmPass"
                type="password"
                :name="Math.random()"
                autocomplete="off"
                :rules="[rules.confirmPass]"
                label="Повторить пароль" 
                color="teal">
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field 
                v-model="tags"
                type="text"
                label="Хештеги (через пробел)" 
                color="teal">
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field 
                v-model="comment"
                type="text"
                label="Примечание" 
                color="teal">
              </v-text-field>
            </v-col>
          </v-row>    
        </v-form>
      </v-card-text>
      <v-card-actions class="pb-4">
        <v-spacer></v-spacer>
        <v-btn dark color="blue-grey lighten-2" @click="close">
          Отмена
        </v-btn>
        <v-btn dark color="teal" @click="save">
          {{ btnText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import SelectUserGroup from '../main/SelectUserGroup'
export default {
  components: {
    SelectUserGroup
  },
  props: {
    editId: null,
    open: {
      default: false,
      type: Boolean
    },
  },
  data () {
    return {
      name: '',  
      email: '',
      password: '',
      confirmPass: '',
      birthday: '',
      sex: 'm',
      tags: '',
      comment: '',
      helmet: null,
      mode: 'training',
      group: null,
      expert: null,
      showPass: false,
      menu: false,
      valid: false,
      role: 1,
      rules: {
        requiredName: value => !!value || 'Введите имя',
        requiredEmail: value => !!value || 'Введите E-mail адрес',
        confirmPass: value => value === this.password  || 'Пароли не совпадают',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Введите корректный E-mail адрес'
        },
      },
      sexes: [{id:'m',text: 'Муж.'}, {id:'f',text: 'Жен.'}, {id:'o',text: 'Другое'}],
      modes: [{id:'free',text: 'Свободный режим'},{id: 'training', text: 'По программе'}],
    }
  },
  watch: {
    editId() {
      let user = this.users.find(u => u.id == this.editId);
      if (user) {
        this.name = user.name;
        this.email = user.email;
        this.birthday = user.birthday;
        this.sex = user.sex;
        this.mode = user.mode;
        this.helmet = user.helmet.id;
        this.group = user.group.id;
        this.expert = user.doctor.id;
        this.comment = user.comment;
        let tags = user.tags ? user.tags.map(t => t.name) : [];
        this.tags =  tags.join(' ');
      } else {
        this.clear();
      }
    },
    name() {
      this.errors = this.errors.filter(e => e.source.pointer != "name")
    },
    email() {
      this.errors = this.errors.filter(e => e.source.pointer != "email")
    },
    password() {
      this.errors = this.errors.filter(e => e.source.pointer != "password")
    },
    menu (val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    },
  },  
  computed: {
    birthdayFormat() {
      let date = this.birthday;
      if (!date) {
        date = new Date().toISOString().substr(0, 10)
      }
      return this.$moment(date).format("DD.MM.YYYY")
    },
    btnText() {
      return this.editId ? 'Обновить' : 'Добавить'
    },
    dialogTitle() {
      return this.editId ? 'Обновить пользователя' : 'Добавить пользователя'
    },
    filtredExperts() {
      return this.experts.filter(e => e.role && e.role.id == 2)
    },
    errors: {
        get() {
          return this.$store.state.auth.authError;
        },
        set(value) {
          this.$store.dispatch('set_errors', value)
        }
    },
    nameErrors: {
        get () {
          return this.errors
            .filter(e => e.source.pointer == "name")
            .map(e => this.$i18n.t(e.detail));
        }
    },
    passErrors: {
        get () {
          return this.errors.filter(e => e.source.pointer == "password");
        }
    },
    emailErrors: {
        get () {
          return this.errors
            .filter(e => e.source.pointer == "email")
            .map(e => this.$i18n.t(e.detail));
        }
    },
    helmets() {
      return this.$store.state.helmets;
    },
    groups() {
      return this.$store.state.userGroups;
    },
    experts() {
      return this.$store.state.experts;
    },
    users() {
      return this.$store.state.users;
    },
    parentGroups() {
      return this.groups.filter(g => g.parent_id == null)
    },
    sortedGroups() {
      let groups = [];
      this.parentGroups.forEach(parent => {
        groups.push(parent);
        let childs = this.groups.filter(g => g.parent_id == parent.id);
        if (childs.length) {
          groups = groups.concat(childs);
        }
      })
      return groups;
    },
    passwordHint() {
      let hint = '';
      if (this.editId) {
        hint += 'При пустом значении пароль останется прежним. ';
      }
      hint += 'Минимальная длина пароля 6 символов';
      return hint;
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
  },
  methods: {

    clear() {
      this.name = '';  
      this.email = '';
      this.password = '';
      this.confirmPassword = '';
      this.birthday = '';
      this.sex = 'm';
      this.helmet = null;
      this.mode = 'training';
      this.group = null;
      this.tags = '';
    },
    close() {
      this.clear();
      this.$emit("close");
    },
    save() {
      if (this.$refs.form.validate()) {
        if (this.editId) {
          this.patch()
        } else {
          this.store()
        }
      }
    },
    store() {
      let url = '/users'
       this.$api.post(url,{
          name: this.name, 
          email: this.email,
          password: this.password,
          birthday: this.birthday,
          sex: this.sex,
          mode: this.mode,
          group_id: this.group,
          expert_id: this.expert,
          helmet_id: this.helmet,
          role: this.role,
          tags: this.tags,
          comment: this.comment,
        })
        .then(() => {
          this.$store.dispatch('addMessage', 'Пользователь сохранен');
          this.$store.dispatch('getUsers');
          this.$store.dispatch('getUserGroups');
          this.$store.dispatch('getTags');
          this.clear();
          this.close();
        })
        .catch(error => {     
          console.log(error);      
          this.$store.dispatch('addRequestError', error)
        })
    },
    patch() {
      let url = `/users/${this.editId}`
       this.$api.post(url,{
          name: this.name, 
          email: this.email,
          password: this.password,
          birthday: this.birthday,
          sex: this.sex,
          mode: this.mode,
          group_id: this.group,
          expert_id: this.expert,
          role: this.role,
          tags: this.tags,
          comment: this.comment,
        })
        .then(() => {
          this.$store.dispatch('getUserGroups');
          this.$store.dispatch('getUsers');
          this.$store.dispatch('getTags');
          this.$store.dispatch('addMessage', 'Изменения сохранены')
          this.clear();
          this.close();
        })
        .catch(error => {           
            this.$store.dispatch('addRequestError', error)
        })
    },
    setBirthday (date) {
      this.$refs.menu.save(date)
    },
    requiredPass(value) {
      if (this.editId) {
        return true;
      }
      return !!value || 'Введите пароль';
    },
    minPass(value) {
      if (this.editId) {
        return true;
      }
      return value.length >= 4 || 'Минимальная длина пароля 4 символов'
    }
  }
}
</script>

<style>

</style>