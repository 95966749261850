<template>
  <div>
    <v-snackbar
      timeout="-1"
      color="red darken-2"
      dark
      v-model="snackbar">
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          
          v-bind="attrs"
          @click="closeSnackbar()">
          Закрыть
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  data: () => ({
    snackbar: false,
    text: '',
  }),
  watch: {
    errors() {
      if (!this.snackbar && this.errors.length) {
        let error = this.errors[0];
        this.text = error.message;
        this.snackbar = true;
      }
    },
  },
  computed: {
    errors: {
        get () {
          return this.$store.state.errors
        },
        set (value) {
          this.$store.dispatch('set_errors',value)
        }
    }
  },
  methods: {
    closeSnackbar() {
      this.snackbar = false;
      this.text = '';
      let errors = this.errors;
      errors.shift();
      this.errors = errors;
    }
  }
}
</script>
