<template>
  <v-navigation-drawer class="no-print" color="white" app clipped v-model="$store.state.showMenu" :mini-variant.sync="mini">
    <v-list
      nav
      dense
      dark
      >
      <v-list-item-group>
        <template v-for="item in items" >
          <v-list-item @click.stop v-if="(!item.isSuperAdmin || isSuperAdmin) && (!item.isAdmin || isAdmin) && (!item.isExpert || isExpert)" light active-class="teal" :key="item.to"  router :to="item.to">
            <v-list-item-icon>
              <v-icon >{{ item.icon }}</v-icon>
            </v-list-item-icon>          
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </v-list-item>
        </template>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  data: () => ({
    items: [
      {name: 'Анализ', to: '/analytics', icon: 'mdi-google-analytics'},
      {name: 'Данные', to: '/data', icon: 'mdi-database', isExpert: true},
      {name: 'Специалисты', to: '/specialists', icon: 'mdi-account-tie', isAdmin: true},
      {name: 'Пользователи', to: '/users', icon: 'mdi-account', isExpert: true},
      {name: 'Программы', to: '/syllabi', icon: 'mdi-head-snowflake', isExpert: true},
      {name: 'Тренинги', to: '/trainings', icon: 'mdi-pencil-box-outline', isSuperAdmin: true},
      {name: 'Сообщения', to: '/messages', icon: 'mdi-message', isExpert: true},
      {name: 'Шлемы', to: '/helmets', icon: 'mdi-safety-goggles', isExpert: true},
    ],
  }),
  computed: {
    mini: {
        get () {
          return !!this.$store.state.mini
        },
        set (value) {
          this.$store.dispatch('setMini',value)
        }
    },
    isSuperAdmin() {
      return this.$store.getters.isSuperAdmin;
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    isExpert() {
      return this.$store.getters.isExpert;
    },
  },
}
</script>
