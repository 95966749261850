<template>
  <v-app>
    <template v-if="$store.getters.isLoggedIn">
      <side-menu/>
      <v-app-bar class="no-print" v-resize="onResize" app clipped-left prominent dense :absolute="isMobile" color="white">
        <v-app-bar-nav-icon @click="toggleMenu"></v-app-bar-nav-icon>
        <img
            alt="VRDoctor Logo"
            class="shrink ml-3 mt-2 d-none d-md-block"
            contain
            src="/Logo.png"
            transition="scale-transition"
            height="80"
          />
          <img
            alt="VRDoctor Logo"
            class="shrink ml-3 mt-1 d-md-none"
            contain
            src="/Logo.png"
            transition="scale-transition"
            height="42"
          />
        <header-left/>
        <v-spacer></v-spacer>
        <header-right/>
      </v-app-bar>
    </template>
    <v-main class="blue-grey lighten-5">
      <!-- Provides the application the proper gutter -->
      <v-container fluid>
        <router-view></router-view>
      </v-container>
      <error-snackbar></error-snackbar>
      <message-snackbar></message-snackbar>
    </v-main>
  </v-app>
</template>

<script>
import SideMenu from "./components/main/SideMenu";
import HeaderLeft from "./components/main/HeaderLeft";
import HeaderRight from "./components/main/HeaderRight";
import ErrorSnackbar from "./components/main/ErrorSnackbar";
import MessageSnackbar from "./components/main/MessageSnackbar";
//import ru from 'vuetify/es5/locale/ru'

export default {
  name: "App",

  components: {
    SideMenu,
    HeaderLeft,
    HeaderRight,
    ErrorSnackbar,
    MessageSnackbar
  },
  created() {
    this.$moment.locale('ru');
    this.$store.dispatch('init')
  },
  data: () => ({
    drawer: true,
    group: null,
    isMobile: false,
  }),  
  computed: {
    
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 1264;
    },
    toggleMenu() {
      if (window.innerWidth >= 1265) {
        this.$store.dispatch('toggleMini')
      } else {
        this.$store.dispatch('toggleMenu')
      }
      
    }
  }
};
</script>
<style lang="scss">
  .w-fit {
    width: fit-content;
  }
  .w-100 {
    width: 100%;
  }
  .h-100 {
    height: 100%;
  }
  .cursor-pointer {
    cursor: pointer;
  }
  .blue-grey.lighten-4.custom {
    &__tab {
      border: 1px solid #B0BEC5 !important; //blue-grey lighten-3
      margin-right: 10px;
      z-index: 5;
      position: relative;
      &__active {
        border-bottom: 1px solid #fff !important;
        background: #fff !important;
      }
    }
  }
  .custom__card {
     border: 1px solid #B0BEC5 !important;
     margin-top: -1px;
  }
  .custom__tabs {
    position: relative;
    z-index: 2;
  }
  .v-expansion-panel-content__wrap {
    padding-left: 0;
    padding-right: 0;
  }
  .trainings {
    &__icon {
      width: 24px;
    }
    &__actions-width {
      width: 108px;
      .sr-item &,
      .sillab-list & {
        width: 72px;
      }
    }
    &__result-count {
      min-width: 24px !important;
    }
  }
  .table-bordered {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12) ;
    th:not(:last-child),
    td:not(:last-child) {
      border-right: 1px solid rgba(0, 0, 0, 0.12);
    }
  }
  .two-actions__width {
    width: 72px;
  }
  .three-actions__width {
    width: 108px;
  }
  .date__width{
    width: 150px;
  }
  .border-bottom {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12) ;
  }
  .w-min {
    width: min-content;
  }
  .mw-550 {
    min-width: 550px;
  }
  a.v-breadcrumbs__item {
    color: #FF7062 !important;
  }
  .v-slide-group__next, 
  .v-slide-group__prev {
    min-width: 36px !important;
    flex: 0 1 36px !important;
  }
  .gr-sort {
    max-width: 206px;
  }
  .message-title {
    word-break: unset;
  }
  .user-ex-panel-content {
    .v-expansion-panel-content__wrap {
      padding: 0;
    }
  }
  @media (max-width: 1264px) {
     .day-new-task {
       &:nth-child(even) {
        background: #ECEFF1;
       }
     }
   }
  @media (max-width: 960px) {
    .gr-filter-row {
      flex-wrap: wrap;
      margin-bottom: 16px;
      .v-input {
        padding-bottom: 20px;
      }
    }
    .message-table .text-width {
      min-width: 320px;
    }
    .v-toolbar {
      height: auto !important;
      padding-top: 6px;
      padding-bottom: 6px;
      &__content {
        height: auto !important;
      }
    }
    .v-breadcrumbs {
      padding: 0 !important;
    }
    .v-main {
      padding-top: 60px !important;
    }
  }
  @media (max-width: 600px) {
    .v-toolbar {
       &__content {
        height: auto !important;
        flex-wrap: wrap;
      }
      &__title {
        width: 100%;
      }
    }
    
    .gr-filter-row {
      position: relative;
      .v-input {
        margin-right: 0 !important;
        margin-left: 0 !important;
        width: 100%;
      }
      .gr-filter-remove {
        position: absolute;
        top: 0;
        right: 0;
      }
    }
    .sr-filter-row {
      flex-wrap: wrap;
       .v-input {
        margin-left: 0 !important;
        width: 100%;
        padding-top: 20px;
      }
    }
    .sr-item,
    .trainings-item {
      .v-expansion-panel-header__icon {
        align-self: flex-start;
        margin-top: 7px;
      }
    }
    .sr-ex-panel-header {
      &.text-caption {
        line-height: 1.1;
      }
      &.sillab-list {
      .v-expansion-panel-header__icon {
        display: none;
      }
    }
    }
    .sr-ex-panel-content {
      .v-expansion-panel-content__wrap {
        padding-left: 12px;
        padding-right: 0;
      }
      .v-expansion-panel-header__icon {
        margin-top: 0;
        .trainings-item & {
          align-self: center;
        }
      }
    }
    .day-new-task {
      .col-12 {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }
  .trainings-item .text-width {
    min-width: 200px;
  }
  .message-table {
    .v-data-table__mobile-row {
      justify-content: flex-start;
      text-align: left;
      &:first-child,
      &:nth-child(2) {
        display: inline-flex;
      }
      &:first-child {
        padding-right: 0;
      }
      .text-width {
        min-width: unset;
      }
    }
    .v-data-table__mobile-row__header {
      padding-left: 0;
    }
    .v-data-table-header-mobile__select {
      min-width: unset;
    }
  }
  .message-title {
    font-size: 1.1em;
    line-height: 1.5;
  }
  @media (max-width: 480px) {
    .gr-add-row {
      flex-wrap: wrap;
    }
    .v-input.gr-sort {
      width: 100%;
      padding-top: 20px;
      margin-left: 0 !important;
    }
  }
  .only-print {
    display: none !important;
  }
  @media print {
    .only-print {
      display: block !important;
    }
    .no-print,
    .no-print * {
        display: none !important;
    }
    .container {
      padding: 0 !important;
      border: none;
    }
    .v-main {
      padding: 0 !important;
      width: 100%  !important;
    }
    .v-sheet.v-card {
      box-shadow: none !important;
      border: none !important;
    }
    .v-data-table__wrapper {
      overflow: hidden !important;
      max-width: 100% !important;
    }
  }
</style>