<template>
  <div>
    <v-select
      v-model="expert"
      :items="experts"
      itemText="name"
      itemValue="id"
      hide-details
      :readonly="isDisabled"
      dense
      label="Специалист"
      color="teal"
      class="flex-grow-0 pt-2"
      item-color="teal lighten-1"/>
  </div>
</template>

<script>
export default {
  props: {
    isDisabled: {
      default: false,
      type: Boolean
    },
  },
  computed: {
    experts() {
        return this.$store.state.experts.filter(e => e.role.id == 2);
    },
    expert: {
      get () {
          return this.$store.state.expert;
      },
      set (value) {
        this.$store.dispatch('setExpert',value)
      }
    }
  }
}
</script>

<style>

</style>